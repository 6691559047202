import { Field } from "react-final-form";
import s from "./OTPInput.module.scss";
import { useRef, useId, useEffect } from "react";
import { removeDuplicateEntries } from "./utils";

function OTPInputView({ value, onChange, className, message, loading }) {
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const previousValues = useRef(new Array(4).fill(""));

  const ref = useRef<HTMLDivElement>(null);
  
  useEffect(() => {
    if (inputRefs.current[0]) {
      inputRefs.current[0].focus();
    }
  }, []);

  useEffect(() => {
    if (message && !loading) {
      inputRefs.current.forEach((input) => {
        if (input) input.value = "";
      });
      previousValues.current = new Array(4).fill("")
    }
  }, [message, loading]);

  const handleKeydown = (e: React.KeyboardEvent<HTMLInputElement>, index) => {
    const _index = Number(e.currentTarget.dataset.otpindex);
    if (e.key === "Backspace") {
      e.preventDefault(); 

      const arr = value.split("");
  
      if (e.currentTarget.value) {
        arr[_index] = "";
        e.currentTarget.value = "";
        previousValues.current[index] = ""

      } else if (_index > 0) {
        arr[_index - 1] = "";
        if (inputRefs.current[_index - 1]) {
          inputRefs.current[_index - 1].focus();
          inputRefs.current[_index - 1].value = "";
          previousValues.current[_index - 1] = "";
        }
      }
      onChange(arr.join(""));
    }
  };

  const handleInput = (e: React.FormEvent<HTMLInputElement>, index) => {
    e.currentTarget.value = e.currentTarget.value.replace(/[^0-9]/g, "");
    let inputValue = e.currentTarget.value.replace(/[^0-9]/g, "");
    const _index = Number(e.currentTarget.dataset.otpindex);

    if (inputValue.length > 1) {
      if (inputValue.length == 2) {
        // replacing the aphabet
        inputValue = removeDuplicateEntries(previousValues.current[index], inputValue);
        inputRefs.current[index].value = inputValue;

        if (_index < 5) {
          inputRefs.current[_index + 1].focus();
        }

        const newValues = inputRefs.current.map(({ value }) => value);

        // update the last values
        previousValues.current[index] = inputValue;
        onChange(newValues.join(""));
      } else {
        // otp paste case

        const otpArray = inputValue.split("").slice(0, 6);
        otpArray.forEach((num, idx) => {
          const targetIndex = _index + idx;
          if (inputRefs.current[targetIndex]) {
            inputRefs.current[targetIndex].value = num;
          }
        });
        const updatedValue = [...value.split("")];

        otpArray.forEach((num, idx) => {
          updatedValue[_index + idx] = num;
        });

        onChange(updatedValue.join(""));
        const nextFocusIndex = _index + otpArray.length;
        if (inputRefs.current[nextFocusIndex - 1]) {
          inputRefs.current[nextFocusIndex - 1].focus();
        }

        // update the last values
        previousValues.current = inputValue.split("");
      }
    } else if (inputValue.length == 1) {
      if (_index < 3) {
        inputRefs.current[_index + 1].focus();
      }

      const newValues = inputRefs.current.map(({ value }) => value);

      // update the last values
      previousValues.current[index] = inputValue;

      onChange(newValues.join(""));
    }
  };

  return (
    <div className={`${s.root} ${className}`} ref={ref}>
      {Array.from({ length: 4 }, (_, index) => (
       <input
       id={`otp_${index}`}
       type="text"
       data-otpindex={index}
       className={s.input}
       onInput={(e)=> handleInput(e, index)} 
       onKeyDown={(e)=> handleKeydown(e, index)}
       disabled={loading}
       autoComplete="one-time-code"
       pattern="[0-9]*" 
       inputMode="numeric"
       ref={(el) => {
         inputRefs.current[index] = el;
       }}
     />
     
      ))}
    </div>
  );
}

export default function OTPInput(props) {
  return (
    <Field
      name={props.name}
      render={({ input: { value, onChange } }) => {
        return (
          <OTPInputView
            value={value}
            onChange={onChange}
            className={props.className}
            message={props.message}
            loading={props.loading}
          />
        );
      }}
    />
  );
}
